import React from 'react';
import videojs from 'video.js'
import {
  isSafari
} from "react-device-detect";

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isReadyToPlay: true,
   isLoaded: false, 
   size: this.props.size
    };
  this.test = this.test.bind(this);
  }
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
     
    });
  }
  componentWillReceiveProps(newProps){
    this.setState({
      size: this.props.size
    })
    }
  
  // destroy player on unmount
   test (){
     
     
     if(this.props.centeredSlide === this.props.index) {
      
        this.player.play();
     }
    
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    
    return (
     
      <div   onMouseOver={event => { 
        if(this.state.isReadyToPlay  && this.state.size.width > 991) {
        this.player.posterImage.hide()
        this.player.play()
        this.setState({isReadyToPlay: false})
      }
      }
      }
      onTouchStart={event => {
        
        if(this.state.isReadyToPlay && this.state.size.width > 991) {
          this.player.posterImage.hide()
          this.player.play()
          this.setState({isReadyToPlay: false})
        }
      } 
    }
    onTouchEnd={event =>{ 
      if(this.state.size.width > 991) {
      this.player.currentTime(0)
      this.player.pause()
      this.player.hasStarted(false)
      this.player.posterImage.show()
       this.setState({isReadyToPlay: true})
      }
    }}
   
      onMouseLeave={event => { 
        if(this.state.size.width > 991) {
        this.player.currentTime(0)
        this.player.pause()
        this.player.hasStarted(false)
        this.player.posterImage.show()
         this.setState({isReadyToPlay: true})
            }}}>	
        <div data-vjs-player
        >
          <video
          autoPlay = {this.props.isIOS || this.state.size.width  <= 991 && this.props.index === 3   ? true : false}
         playsInline 
           
           className="video-slide" 
           onFocus={event => {
           
           }}
          onLoadedData ={event =>{
            this.setState({isLoaded: true})
           }}
          
          onEnded={event => { 
         
           
           this.player.currentTime(0)
           this.player.pause()
           this.player.hasStarted(false)
           this.player.posterImage.show()
               }}
          
              ref={ node => this.videoNode = node } className="video-js" controls={false}>
                {this.state.isLoaded  && this.state.size.width <= 991 
                && this.test()}
              </video>
          
        </div>
      </div>
    )
  }
}