import React from "react"
import { Container, Row, Col } from "reactstrap"
import linkArrow from "../images/link-arrow.png"

export const Staff = ({ officesTitle, staffTitle, staff, email }) => (
  <React.Fragment>
    <Container className="text-right ">
      <div className="font-weight-bold font-bigger text-uppercase pt-5">
        {staffTitle ? staffTitle : `Team`}
      </div>
      <Row className="pt-5">
        {staff.map((person, index) => 
          <Col 
            key={index}
            className="py-5"
            xs={{
              size: 12
            }}
            md={{
              size: 5
            }}
          >
            <Person email={email} key={index} person={person}/>
          </Col>
        )}
      </Row>
      <Row className="pt-3">
        <Col 
          className="py-3 mb-5"
          xs={{
            size: 12
          }}
        >
          <a className="font-weight-bold " href="/contact">
            {officesTitle ? officesTitle : `Contact`}
            <img className="link-arrow" src={linkArrow} alt={officesTitle ? officesTitle : `Contact`}/>
          </a>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
)

export const Person = ({ person, position="right", padding=0, width=100, marginLeft=0, personText='', email, visibility = 'visible'}) => (
<div 
  className={`text-${position} ${position} ${visibility} ${personText}`} 
  style={{
    padding: `${padding}px`,
    width: `${width}%`,
    marginLeft: `${marginLeft}%`
  }} 
>
    {person.name && 
      <div className={`font-weight-bold font-bigger `}>
        {person.name}
      </div>
    }
    {person.job_title && 
      <div className="font-bigger pb-3">
        {person.job_title}
      </div>
    }
    {person.membership && 
      <div>
        <span className="font-weight-bold">{person.membership_title && person.membership_title[0].text}: </span>
        {person.membership}
      </div>
    }
    {person.education && 
      <div>
        <span className="font-weight-bold">{person.education_title && person.education_title[0].text}: </span>
        {person.education}
      </div>
    }
    {person.practice_area && 
      <div>
        <span className="font-weight-bold">{person.practice_area_title && person.practice_area_title[0].text}: </span>
        {person.practice_area}
      </div>
    }
    {person.languages && 
      <div>
        <span className="font-weight-bold">{person.languages_title && person.languages_title[0].text}: </span>
        {person.languages}
      </div>
    }
    {email && person.eMail && 
      <div>
        <span className="font-weight-bold">{person.eMail_title && person.eMail_title[0].text}: </span>
        <a href={`mailto:${person.eMail}`}>{person.eMail}</a>
      </div>
    }
 </div>
)

