import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StaffSlider from "../components/staffSlider"
import HomepageText from "../components/homepageText"
import ReactCursorPosition from 'react-cursor-position'
import useWindowSize from '../utils/use-windows-size'

const LegaliaPage = ({ data, path, pageContext }) => {

  const [imageHovered, setImageHover] = useState(false)
  const {
    meta_title, 
    meta_description, 
    company_logo, 
    about_title,
    about_company,
    offices_title,
    offices,
    staff_title,
    staff,
    _meta
  } = data.prismic.allHomes.edges[0].node
  return(
    <Layout 
      offices={offices} 
      path={path} 
      staffTitle={staff_title} 
      officesTitle={offices_title} 
      title={meta_title}
      aboutTitle={about_title}
      darkBackground={false}
      lang={_meta.lang}
      pageLanguage={pageContext.siteLanguage}
      uid={'legalia'}
    >
      <SEO title={meta_title} description={meta_description} />
      <section>
        <ReactCursorPosition>
          <StaffSlider title={meta_title} staff={staff} setImageHover={setImageHover} />
        </ReactCursorPosition>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LegaliaPage($locale: String!) {
    prismic {
      allHomes(lang: $locale) {
        edges {
          node {
            meta_title
            meta_description
            company_logo
            about_title
            about_company
            _meta{
              lang
            }
            offices_title
            offices {
              postal_code
              eMail
              address
              telephone
              city
              country
            }
            staff_title
            staff {
              eMail_title
              eMail
              practice_area_title
              practice_area
              name
              membership_title
              membership
              languages_title
              languages
              job_title
              education_title
              education
              img
              video {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  size
                }
            
              }
              mp4 {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`

export default LegaliaPage
