import React, { useState, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { map, prop } from "ramda"
import Player from "./video"
import "../../node_modules/video.js/dist/video-js.css"
import ReactCursorPosition from "react-cursor-position"
import {Person} from "./staff"
import useWindowSize from '../utils/use-windows-size';
import {
  isIOS,
  isSafari
} from "react-device-detect";
const Slide = ({ video, img, position, slideWidth, numberOfSlides, staff, setImageHover, mp4, index, size, currentSlide, staffLength, elementDimensions }) => {
  const [hover, setHover] = useState(false)
  const [currentStyle, setStyle] = useState()
  const player = useRef(null)
  const videoJsOptions = {
    autoplay: isIOS,
   // controls: false,
   dots:true,
    sources: [
      {
        src: video,
        type: 'video/webm'
      },
      { 
        src: mp4,
        type: 'video/mp4'
      }
    ],
    responsive: "false",
    poster: img,
   
  }
  
  const checkPositionOfASlide = (
    position,
    slideWidth,
    numberOfSlides,
    i = 0
  ) => {
    if (position >= slideWidth * i && position <= slideWidth * (i + 1)) {
      return i
    } else {
      if (numberOfSlides < i) {
       
      } else {
        return checkPositionOfASlide(
          position,
          slideWidth,
          numberOfSlides,
          i + 1
        )
      }
    }
  }
  const checkTextPosition = (slidePosition, numberOfSlides) => {
  
    if (numberOfSlides % 2 === 1) {
      const center = (numberOfSlides - 1) / 2 + 1
      if (numberOfSlides === 1) {
        return "right"
      } else {
        return slidePosition > center
          ? "right"
          : slidePosition < center
          ? "left"
          : "right"
      }
    } else {
      return slidePosition <= numberOfSlides / 2 ? "left" : "right"
    }
  }
  const handleHover = () => {
    const slidePosition =
      checkPositionOfASlide(position, slideWidth, numberOfSlides) + 1
    const style = checkTextPosition(slidePosition, numberOfSlides) 
    setStyle(style)
    setImageHover(true)
    setHover(true)
  }
  const centeredSlide = currentSlide === staffLength - 1 ? 0 :  currentSlide + 1;
  const visibility = centeredSlide  === index ? ' visible' :   ' hidden'
 
  return (
    <div onMouseOver={() => handleHover()} 
    onTouchStart={() => handleHover()
      
    } 
    onTouchEnd={() => {setImageHover(false); setHover(false)} }
    onMouseOut={() => {setImageHover(false); setHover(false)}}>
      <Player ref={player} isIOS={isIOS} centeredSlide={centeredSlide} index={index} size={size} {...videoJsOptions} muted></Player>
      { hover && size.width >= 991  ?
      <Person person={staff}  className={currentStyle} position={currentStyle} padding={25} width={130} marginLeft={currentStyle === 'right' ? -30 : 0} ></Person>
    :  size.width <= 991    && 
    <Person person={staff} className={currentStyle} visibility={visibility} position={currentStyle } personText={'person-text'} padding={25} width={130} marginLeft={currentStyle === 'right' ? -30 : 0} ></Person>
    }
    </div>
  )
}
const StaffSlider = ({ title, staff, elementDimensions, position, setImageHover }) => {
  const [currentSlide, setCurrentSlide] = useState(2);
  const numberOfSlides =
    elementDimensions.width > 991
      ? 5
      : elementDimensions.width < 991 && elementDimensions.width > 767
      ? 3
      : 1
  const slideWidth = elementDimensions.width / numberOfSlides
  const videos = map(x => prop("url", x.video), staff)
  const setCurSlide = (slide) => {
    slide < staff.length -1 ?
    setCurrentSlide(0)
    : setCurrentSlide(slide)
  }
  const settings = {
    className: "center",	
    centerPadding: "60px",
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  
   beforeChange: (oldI, newI) =>{ setCurrentSlide(newI);
},
    
  }
  const size =useWindowSize();
  
 // let refArr = useRef(null);
  return (
    <React.Fragment>
      <ReactCursorPosition>
        <Slider {...settings}>
          {staff.map((member, index) => (
            <Slide
              key={index}
              video={prop("url", member.video)}
              img={prop("url", member.img)}
              mp4={prop("url", member.mp4)}
              position={position.x}
              slideWidth={slideWidth}
              numberOfSlides={numberOfSlides}
              staff={member}
              setImageHover= {setImageHover}
              index={index}
              size={size}
             currentSlide={currentSlide}
              staffLength={staff.length}
              elementDimensions={elementDimensions}
            ></Slide>
          ))}
        </Slider>
      </ReactCursorPosition>
    </React.Fragment>
  )
}

export default StaffSlider
