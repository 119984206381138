import React from "react"
import { Container, Row, Col } from "reactstrap"
import lightLogo from "../images/white-logo.svg"
import linkArrow from "../images/link-arrow.png"

const HomepageText = ({ staffTitle, aboutCompany, companyLogo, title, pageLanguage }) => {
  const prefix = pageLanguage === 'et-et' ? `` : `${pageLanguage}/`
  return(
    <React.Fragment>
      <Container>
        <Row className="pt-3">
          <Col
            className="text-right py-3"
            xs={{
              size: 8,
              offset: 4
            }}
            sm={{
              size: 5,
              offset: 7
            }}
            lg={{
              size: 3,
              offset: 9
            }}
          >
            <img 
              className="d-lg-none"
              src={lightLogo}
              alt={title}
              style={{
                width: `40px`,
                height: `40px`,
                marginBottom: `0`
              }}
            />
            <img 
              className="d-none d-lg-block"
              src={companyLogo.url} 
              alt={companyLogo.alt ? companyLogo.alt : title}
            />
          </Col>
        </Row>
        <Row>
          <Col 
            className="text-right py-3"
            xs={{
              size: 11,
              offset: 1
            }}
            md={{
              size: 6,
              offset: 6
            }}
          >
            {aboutCompany.split('\n').map((item, index) => (index === 0) ? item : [<br key={index} />, item])}
          </Col>
        </Row>
        <Row>
          <Col 
            className="text-right py-3"
            xs={{
              size: 11,
              offset: 1
            }}
            md={{
              size: 6,
              offset: 6
            }}
          >
            <a className="font-weight-bold d-lg-none" href={`/${prefix}legalia`}>
              {title ? title : `Legalia`} 
              <img className="link-arrow" src={linkArrow} alt={title ? title : `Legalia`} />
            </a>
            <a className="font-weight-bold d-none d-lg-block" href={`/${prefix}team`}>
              {staffTitle ? staffTitle : `Team`} 
              <img className="link-arrow" src={linkArrow} alt={staffTitle ? staffTitle : `Team`} />
            </a>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default HomepageText